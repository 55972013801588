export const environment = {
  production: false,
  API_URL: "https://sae-back-dev.cintuz.com/api",
  recaptcha: {
    siteKey: "6Lc2hAooAAAAAELa04wCdTLwsP_2OfRWm3s0tTAs"
  },
  VAPID_KEY: "BHToxn_wv8FhS0rwa39VzmAqJXfn0ScFVlo0JsuJo4dqU2I1Eh-pAj5PoEK65FGwnPMoo5qlA9Y-QZpHxzt_Phg",
  LINK_WEB_SAE: 'https://sae-web-dev.gsoxware.io/#/home',
  GOOGLE_MAP_API_KEY: "AIzaSyCbdQApB2Yu8YTYToQxLiy5iQbasvtUtJE",
  WAPP_LINK: "https://wa.me/584125773876",
  VERSION_APP: "1.0",
  APP_NAME: "SAE - App"
};

// command show SHA-1 y SHA-526 ------->      keytool -list -keystore C:\Users\Orianna\OneDrive\Documents\Gsoxware\sae-app\sae-app\src\.well-known\com.sae.app.jks
// password Abcd12345